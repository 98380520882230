import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {IDateRange} from '@joc/api-gateway';

type StatusesContextValues = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  filters: IDateRange;
  setFilters: Dispatch<SetStateAction<IDateRange>>;
  sidebarVisibility: boolean;
  setSidebarVisibility: Dispatch<SetStateAction<boolean>>;
};

const StatusesContext = createContext<StatusesContextValues>({} as StatusesContextValues);

export const StatusesContextProvider = StatusesContext.Provider;
export const useStatusesContext = () => useContext(StatusesContext);
