import {FC, useContext, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {ReactComponent as ManualOppo} from 'assets/icons/heart.svg';
import {ReactComponent as PrevoiusCompleted} from 'assets/icons/previous-completed.svg';
//redux
import {Store} from 'redux/root';
import {useSelector} from 'react-redux';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetUserVolunteerId} from 'redux/user-service/selector';
//context
import {OpportunitiesListContext} from '../../OpportunitiesListContext';
//types
import {
  IOpportunityResponse,
  OPPORTUNITY_ACTIVE_STATUS,
  OPPORTUNITY_STATUSES,
  OPPORTUNITY_TYPES,
  OPPORTUNITY_VOLUNTEER_STATUS,
} from '@joc/api-gateway';
import {JOIN_STATE} from 'core/types';
//functions
import {getImageType, getOpportunityImagePath} from 'core/functions';
//components
import PossibleVolunteersItem from 'shared/components/PossibleVolunteersItem';
import VolunteerStatus from './VolunteerStatus';
import PopupJoinCongrats from './PopupJoinCongrats';
import DateDetails from './DateDetails';
import Action from './Action';
import {DistanceInfo, Image} from './Renderers';
import {VolunteerLimit} from 'shared/components/VolunteerLimit/VolunteerLimit';
import PopupContainer from 'shared/components/PopupContainer';
import ResponseFailure from 'shared/components/ResponseFailure';

//styles
import styles from './OpportunityItem.module.scss';
import {ORGANISATION_GENERAL_TYPES} from '@joc/api-gateway/lib/api-client';

type OpportunityItemProps = {
  isCompletedTab?: boolean;
  opportunity: IOpportunityResponse & {createdByAdmin?: boolean};
  buttonFailureClickHandler?: () => void;
};

const OpportunityItem: FC<OpportunityItemProps> = ({opportunity, buttonFailureClickHandler, isCompletedTab}) => {
  const {t} = useTranslation(['buttons', 'errors', 'common', 'inviteColleagues']);
  const {isVolunteerOpportunities} = useContext(OpportunitiesListContext);

  const error = useSelector(selectorGetError);
  const userVolunteerId = useSelector((store: Store) => selectorGetUserVolunteerId(store));
  const [joinState, setJoinState] = useState<JOIN_STATE>(JOIN_STATE.NONE);

  const [currentTime, setCurrentTime] = useState(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'));

  const imageType = getImageType(opportunity);
  const imagePath = getOpportunityImagePath(opportunity);

  /**
   * TODO: The solution is temporary to satisfy the customer's wishes. You need to connect web sockets
   */
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ'));
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const isChoiceIsAllowed = moment(currentTime).isSameOrAfter(opportunity.startDate);
  const isVisibleVolunteersLimit = useMemo(() => Boolean(opportunity.volunteersMaxCount), [opportunity]);

  const resetClickHandler = () => {
    buttonFailureClickHandler?.();
  };

  const errorMaxVolunteers = useMemo(
    () => error.state && error.message === 'Reached max volunteers count',
    [error.state]
  );

  const errorPopupMessage = useMemo(
    () => (errorMaxVolunteers ? t('errors:errorMaxVolunteerCount') : t('errors:errorPlease')),
    [errorMaxVolunteers]
  );

  const errorPopupTitle = useMemo(
    () => (errorMaxVolunteers ? t('buttons:button.back') : t('errors:checkAndTryAgain')),
    [errorMaxVolunteers]
  );

  const isAdmin = useMemo(
    () => opportunity.volunteers?.length === 1 && opportunity.volunteers[0].userId !== opportunity.user?.id,
    [opportunity]
  );

  const isManualOpportunity = useMemo(() => opportunity.opportunityType === OPPORTUNITY_TYPES.MANUAL, [opportunity]);

  if (error.state) {
    return (
      <PopupContainer isDisableCloseButton isDisablePadding isContentCenter isDisableContentMarginTop>
        <ResponseFailure
          message={errorPopupMessage}
          buttonTitle={errorPopupTitle}
          buttonClickHandler={resetClickHandler}
          isReloginDisabled
          isVolunteersLimit
        />
      </PopupContainer>
    );
  }

  return (
    <div
      key={opportunity.id}
      className={cx(
        styles.feed_item,
        (opportunity.opportunityActiveStatus?.status === OPPORTUNITY_ACTIVE_STATUS.SUSPENDED ||
          opportunity?.status === OPPORTUNITY_STATUSES.PENDING) &&
          styles.suspended
      )}
    >
      <Image
        type={imageType}
        imagePath={imagePath}
        organizationLogoPath={opportunity.organisation?.organizationLogoPath}
        isManual={isManualOpportunity}
      />
      <div className={styles.item__main}>
        <div className={styles.item__title}>
          {isManualOpportunity && (
            <div className={styles.manualOpportunity__mark}>
              <div className={styles.manualOpportunity__mark_manual}>
                {!opportunity?.isInitial && <ManualOppo height={20} width={20} />}
                {opportunity?.isInitial && (
                  <PrevoiusCompleted height={25} width={25} className={styles.previous_completed_mark} />
                )}{' '}
              </div>
              {opportunity?.createdByAdmin && (
                <p className={styles.manualOpportunity__mark_admin}>{t('common:admin')}</p>
              )}
            </div>
          )}
          <div className={styles.item__title__text}>{opportunity.opportunityName}</div>
        </div>
        <div className={styles.item__details}>
          <DistanceInfo userVolunteerId={userVolunteerId} opportunity={opportunity} />
          {isVisibleVolunteersLimit && (
            <div className={styles.volunteersLimit__wrapper}>
              <VolunteerLimit
                isCentrePosition
                maxVolunteers={opportunity.volunteersMaxCount}
                currentVolunteers={opportunity.volunteers?.length || 0}
              />
            </div>
          )}
          <div className={styles.item__feedParty}>
            {!isManualOpportunity ? (
              <span>
                <b>
                  {opportunity.volunteers?.filter((vol) => vol.status === OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM)
                    .length || 0}
                </b>{' '}
                {opportunity.organisation?.organisationGeneralType === ORGANISATION_GENERAL_TYPES.SCHOOL
                  ? t('inviteColleagues:students2')
                  : t('inviteColleagues:volunteers2')}
              </span>
            ) : (
              ''
            )}

            {/*{!!opportunity.volunteers?.length && (*/}
            {/*  <PossibleVolunteersItem*/}
            {/*    opportunity={opportunity}*/}
            {/*    volunteers={opportunity.volunteers}*/}
            {/*    opportunityId={opportunity.id}*/}
            {/*    isApprovalRequired={opportunity.isApprovalRequired}*/}
            {/*    opportunityName={opportunity.opportunityName}*/}
            {/*    opportunityStatus={opportunity?.status}*/}
            {/*    opportunityType={opportunity.opportunityType}*/}
            {/*    actionsDisable*/}
            {/*    isViewVolunteersDisable*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>
        <div className={styles.item__feedOrganizator}>
          {opportunity.organisation?.organizationName || 'Private'}
          {!!opportunity.tagId && opportunity.tag && (
            <div className={styles.item__feed__hashtag}>{`#${opportunity.tag}`}</div>
          )}
        </div>
        {isVolunteerOpportunities && (
          <VolunteerStatus
            isAdminOppo={opportunity.createdByAdmin}
            isCompletedTab={isCompletedTab}
            isChoiceIsAllowed={isChoiceIsAllowed}
            itemValue={opportunity}
            userVolunteerId={userVolunteerId}
          />
        )}
        <DateDetails opportunityInfo={opportunity} />
        {opportunity.opportunityActiveStatus?.status !== OPPORTUNITY_ACTIVE_STATUS.SUSPENDED && (
          <Action
            isAdmin={opportunity.createdByAdmin}
            resetClickHandler={resetClickHandler}
            opportunity={opportunity}
            setJoinState={setJoinState}
          />
        )}
      </div>
      {joinState === JOIN_STATE.SUCCESS && (
        <PopupJoinCongrats setJoinState={setJoinState} isApprovalRequired={opportunity.isApprovalRequired} />
      )}
    </div>
  );
};

export default OpportunityItem;
