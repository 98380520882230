import {Dispatch} from 'redux';
//API
import {API} from 'core/API';
//redux
import {
  CREATE_SMART_GROUP,
  DELETE_SMART_GROUP,
  EDIT_SMART_GROUP,
  GET_SMART_GROUPS_ALL,
  SET_ERROR,
} from 'redux/actions-types';
//i18n
import i18n from 'components/translate';
//types
import {GroupsRequest, IGroupsRequest, SearchVolunteersByGroupRequest} from '@joc/api-gateway';
import {ChangeSequenceNumRequest} from '@joc/api-gateway/lib/api-client';

export const getSmartGroupsAll =
  (orgId: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.getAllGroups(orgId.toString());
      dispatch({type: GET_SMART_GROUPS_ALL, payload: response});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const changeSmartGroupOrder =
  (smartGroupId: number, order: number, orgId?: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const response = await API.changeGroupSequenceNum(
        smartGroupId,
        orgId ? String(orgId) : undefined,
        ChangeSequenceNumRequest.fromJS({sequenceNum: order})
      );

      console.log(response);
      // dispatch({type: GET_SMART_GROUPS_ALL, payload: response});
    } catch (error) {
      dispatch({type: SET_ERROR, payload: {state: true, message: error?.response?.message || error.message}});
    }
  };

export const createSmartGroup = (body: IGroupsRequest) => async (dispatch: Dispatch) => {
  try {
    const response = await API.createGroup(
      body?.organizationId ? String(body.organizationId) : undefined,
      GroupsRequest.fromJS(body)
    );
    const volunteersResponse = await API.getAllVolunteersByGroup(
      SearchVolunteersByGroupRequest.fromJS({groupId: response.id, organizationId: body.organizationId})
    );
    dispatch({type: CREATE_SMART_GROUP, payload: {...response, total: volunteersResponse.total}});
    return response;
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {state: true, message: error?.response?.message || error?.response?.message || error.message},
    });
  }
};

export const editSmartGroup = (id: number, body: IGroupsRequest) => async (dispatch: Dispatch) => {
  try {
    const response = await API.updateGroup(
      id,
      body?.organizationId ? String(body?.organizationId) : undefined,
      GroupsRequest.fromJS(body)
    );
    const volunteersResponse = await API.getAllVolunteersByGroup(
      SearchVolunteersByGroupRequest.fromJS({groupId: response.id, organizationId: body.organizationId})
    );
    dispatch({type: EDIT_SMART_GROUP, payload: {...response, total: volunteersResponse.total}});
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {state: true, message: error?.response?.message || error?.response?.message || error.message},
    });
  }
};

export const deleteSmartGroup = (id: number | undefined, orgId: number) => async (dispatch: Dispatch) => {
  try {
    if (!id)
      return dispatch({
        type: SET_ERROR,
        payload: {state: true, message: `${i18n.t('errors:sorry')} ${i18n.t('errors:cantFindGroupId')}`},
      });
    await API.deleteGroup(id, orgId.toString());
    dispatch({type: DELETE_SMART_GROUP, payload: id});
  } catch (error) {
    dispatch({
      type: SET_ERROR,
      payload: {state: true, message: error?.response?.message || error?.response?.message || error.message},
    });
  }
};
