import {FC, useEffect, useRef, useState} from 'react';
import moment, {Moment} from 'moment';

// new material-ui
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

// components
import FieldWrapper from 'shared/inputs/FieldWrapper';

// hooks
import {useClickOutside} from 'hooks/useClickOutside';

// types
import {FieldBaseProps} from '../../../../../../../core/types';

// styled
import {TimePickerStyled} from './styles';

export const StatusTimePicker: FC<FieldBaseProps> = (fieldBaseProps) => {
  const [value, setValue] = useState<Moment | null>(null);
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const handleClosePicker = () => setIsPickerOpen(false);
  const popperRef = useClickOutside<HTMLDivElement>(handleClosePicker);
  const inputRef = useRef<HTMLImageElement>();

  const onChange = (value: Moment | null) => {
    if (moment(new Date(), 'HH:mm').isAfter(moment(value, 'HH:mm'))) {
      setFieldValue(field.name, moment().add({minute: 1}).toDate());
    } else {
      setFieldValue(field.name, value?.toDate());
    }
    setIsHighlight(true);
  };

  useEffect(() => {
    if (moment(field.value).isValid()) {
      setFieldTouched(field.name, true);
      setIsHighlight(!!field?.value);
    }
    setValue(moment(field.value));
  }, [field.value]);

  const disableAmMenuItem = () => {
    if (value?.isSame(moment(), 'day') && Number(moment().format('HH')) >= 12) {
      setTimeout(() => {
        const liAm = popperRef.current?.querySelector('li[aria-label="AM"]');
        liAm?.setAttribute('aria-disabled', 'true');
        liAm?.classList.add('Mui-disabled');
      }, 100);
    }
  };

  return (
    <FieldWrapper {...fieldBaseProps}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <TimePickerStyled
          disabled={disabled}
          value={value}
          onChange={onChange}
          views={['hours', 'minutes']}
          timeSteps={{hours: 1, minutes: 1}}
          onOpen={disableAmMenuItem}
          selectedSections={null}
          open={isPickerOpen}
          disablePast={value?.isSame(moment(), 'day')}
          onAccept={handleClosePicker}
          slotProps={{
            textField: {
              inputRef,
              onClick: () => {
                inputRef.current?.blur();
                setIsPickerOpen(true);
              },
              sx: {
                '.MuiOutlinedInput-input': {
                  color: isHighlight ? '#1C2F4C !important' : '#5B768B !important',
                },
              },
            },
            popper: {
              ref: popperRef,
              sx: {
                '.MuiPaper-root': {
                  borderRadius: '10px',
                },
                li: {
                  '&.Mui-selected': {
                    backgroundColor: '#4B5FF6 !important',

                    '&:hover': {
                      backgroundColor: '#4B5FF6',
                    },
                  },
                  '&:focus': {
                    color: '#ffffff',
                    backgroundColor: '#0017D0',
                  },
                },
              },
            },
            actionBar: {
              sx: {
                button: {
                  color: '#4B5FF6',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </FieldWrapper>
  );
};
