import qs from 'qs';
import moment from 'moment';
import {TFunction} from 'react-i18next';
//types
import {
  OPPORTUNITY_VOLUNTEER_STATUS,
  ISearchOpportunitiesRequest,
  OPPORTUNITY_STATUSES,
  PaginationRequest,
  OPPORTUNITY_FILTER_TYPE,
} from '@joc/api-gateway';
import {DataTabs, SetupDataConfig} from './interfaces';
//constants
import {VOLUNTEER_TABS} from 'core/constants';

type ParsedSearchOpportunitiesQuery = ISearchOpportunitiesRequest & {
  tabName?: VOLUNTEER_TABS;
};

export const parseQueryString = (search: string): ParsedSearchOpportunitiesQuery =>
  qs.parse(search, {ignoreQueryPrefix: true});

export const getOppoRequestBody = (
  volunteerId: string | number,
  pagination?: PaginationRequest,
  tabName?: VOLUNTEER_TABS,
  defaultRequestBody?: ISearchOpportunitiesRequest
) => {
  const startDay =
    tabName === VOLUNTEER_TABS.UPCOMING ? {fromDate: `${moment().format('YYYY-MM-DDThh:mm:ss')}.000Z`} : {};
  const status = !tabName ? [OPPORTUNITY_STATUSES.CLOSED] : defaultRequestBody?.status;
  const volunteerStatus = !tabName ? [OPPORTUNITY_VOLUNTEER_STATUS.CONFIRM] : defaultRequestBody?.volunteerStatus;

  return {
    ...defaultRequestBody,
    status,
    startDay,
    pagination,
    volunteerStatus,
    volunteerId: +volunteerId,
  };
};

export const oppoFilterType = (tabName?: VOLUNTEER_TABS): OPPORTUNITY_FILTER_TYPE => {
  switch (tabName) {
    case VOLUNTEER_TABS.ALL:
      return OPPORTUNITY_FILTER_TYPE.ALL;
    case VOLUNTEER_TABS.COMPLETED:
      return OPPORTUNITY_FILTER_TYPE.COMPLETED;
    case VOLUNTEER_TABS.UPCOMING:
      return OPPORTUNITY_FILTER_TYPE.UPCOMING;
    case VOLUNTEER_TABS.MANUAL:
      return OPPORTUNITY_FILTER_TYPE.SOLO;
    case VOLUNTEER_TABS.REJECTED:
      return OPPORTUNITY_FILTER_TYPE.REJECTED;
    case VOLUNTEER_TABS.CANT_MAKE_IT:
      return OPPORTUNITY_FILTER_TYPE.MISSED;
    case VOLUNTEER_TABS.PAST:
      return OPPORTUNITY_FILTER_TYPE.PAST;
    default:
      return OPPORTUNITY_FILTER_TYPE.COMPLETED;
  }
};

export const getPopupConfig = (
  currentTab: string | null,
  dataTabs: DataTabs,
  fn: () => void,
  translator: TFunction<('messages' | 'buttons' | 'myChesed')[], undefined>
) => {
  const foundCurrentTab =
    currentTab !== null
      ? Object.entries(dataTabs).find(([_key, value]) => value.includes(currentTab as VOLUNTEER_TABS))?.[0]
      : Object.keys(dataTabs)[2];

  const setupData: Record<string, SetupDataConfig> = {
    positiveTabs: {
      textTitle: translator('myChesed:popup.positiveTabs.title'),
      buttonText: translator('buttons:button.logAKindness'),
      fn,
    },
    negativeTabs: {textTitle: translator('myChesed:popup.negativeTabs.title'), buttonText: '', fn: () => {}},
    completedTab: {
      textTitle: translator('myChesed:popup.completedTab.title'),
      buttonText: translator('buttons:button.logAKindness'),
      fn,
    },
  };

  return foundCurrentTab ? setupData[foundCurrentTab] : setupData.completedTab;
};
