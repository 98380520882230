import {createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {urls} from 'core/appUrls';
//redux
import {selectorGetOpportunitiesTotal} from 'redux/opportunities-service/selector';
import {findChesed, resetOpportunities} from 'redux/opportunities-service/action';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetError} from 'redux/error-service/selector';
import {selectorGetUserAddress} from 'redux/user-service/selector';
import {selectorGetFilter} from 'redux/filter-service/selector';
import {resetFilter} from 'redux/filter-service/actions';
//hooks
import useEffectNoFirstMount from 'hooks/useEffectNoFirstMount';
//types
//components
import Loader from 'shared/components/Loader';
import OpportunityContainer from 'components/Volunteer/Opportunities/OpportunitiesList/OpportunityContainer';
import SidebarContainer from 'components/Volunteer/Opportunities/SidebarContainer';
import OpportunitiesFilters from 'components/Volunteer/Opportunities/OpportunitiesFilters';
import OpportunitiesList from 'components/Volunteer/Opportunities/OpportunitiesList';
import ResponseFailure from 'shared/components/ResponseFailure';
//styles
import styles from './FeedV.module.scss';
import useThunkDispatch from '../../../hooks/useThunkDispatch';

type FeedVContextType = {
  isClearSearch: boolean;
  setIsClearSearch: Dispatch<SetStateAction<boolean>>;
};

export const FeedVContext = createContext({} as FeedVContextType);

const FeedPage: FC = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();

  const {t} = useTranslation(['messages', 'buttons']);

  const [isLoading, setIsLoading] = useState(true);
  const [isClearSearch, setIsClearSearch] = useState(true);

  const filter = useSelector(selectorGetFilter);
  const opportunitiesTotal = useSelector(selectorGetOpportunitiesTotal);
  const error = useSelector(selectorGetError);
  const userAddress = useSelector(selectorGetUserAddress);

  useEffect(() => {
    return () => {
      dispatch(resetError());
      dispatch(resetOpportunities());
      dispatch(resetFilter());
    };
  }, []);

  const isFilterClear = useMemo(() => isClearSearch, [filter]);

  const buttonFailureClickHandler = () => {
    dispatch(resetError());
    dispatch(resetOpportunities());
    dispatch(resetFilter());
    setIsClearSearch(true);
  };

  const buttonKindnessClickHandler = () => history.push(urls.volManualOpportunity);

  const getOpportunitiesRequest = useCallback(async () => {
    if (filter) {
      try {
        setIsLoading(true);
        const requestBody = {...filter};
        if (!requestBody.fullTextSearch?.value) delete requestBody.fullTextSearch;

        await dispatch(findChesed(userAddress, requestBody));

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        dispatch(setError(error?.response?.message || error.message, error?.response?.code || error.code));
      }
    }
  }, [filter, userAddress]);

  useEffectNoFirstMount(() => {
    getOpportunitiesRequest();
  }, [getOpportunitiesRequest]);

  return (
    <OpportunityContainer>
      <SidebarContainer>
        <FeedVContext.Provider value={{isClearSearch, setIsClearSearch}}>
          <OpportunitiesFilters hideForWho />
        </FeedVContext.Provider>
      </SidebarContainer>
      <div className={styles.feed__main}>
        {isLoading && !opportunitiesTotal ? (
          <Loader />
        ) : !opportunitiesTotal && !isLoading && isFilterClear ? (
          <ResponseFailure
            styleContact
            title={t('messages:noOpportunitiesYet')}
            message={error.message || t('messages:opportunitiesListed')}
            buttonTitle="Log a Kindness"
            buttonClickHandler={buttonKindnessClickHandler}
          />
        ) : !opportunitiesTotal && !isLoading ? (
          <ResponseFailure
            title=" "
            styleEmptyChesed
            message={error.message || t('messages:noRelevantOpportunities')}
            buttonTitle={t('buttons:button.resetFilters')}
            buttonClickHandler={buttonFailureClickHandler}
            parentClassName={styles.feed__main__relevant__opportunities}
          />
        ) : (
          <OpportunitiesList isLoading={isLoading} buttonFailureClickHandler={buttonFailureClickHandler} />
        )}
      </div>
    </OpportunityContainer>
  );
};

export default FeedPage;
