import React from 'react';
import {createStyles} from '@material-ui/core/styles';
import {Dialog, withStyles, WithStyles} from '@material-ui/core';
import cx from 'classnames';

const stylesForDialog = () =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      top: '100px !important',

      '& .MuiPaper-rounded': {
        borderRadius: 10,
        boxShadow: 'none',
      },
    },
    backdrop: {
      top: 100,
      backgroundColor: 'rgba(241, 243, 255, 0.8)',
    },
    paper: {
      margin: '32px 10%',
      width: '100%',
      maxWidth: '1300px',
    },
    rootFullScreen: {
      width: '100%',

      '& > .MuiDialog-container': {
        width: '100%',
        maxWidth: '1920px !important',
      },
    },
  });

export interface DialogStylesProps extends WithStyles<typeof stylesForDialog> {
  children: React.ReactNode;
  open: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isFullScreen?: boolean;
}

const DialogStyled = withStyles(stylesForDialog)((props: DialogStylesProps) => {
  const {children, classes, isFullScreen = false, ...other} = props;
  return (
    <Dialog
      className={cx(classes.root, {[classes.rootFullScreen]: isFullScreen})}
      {...other}
      BackdropProps={{className: classes.backdrop}}
      PaperProps={{className: isFullScreen ? classes.paper : undefined}}
    >
      {children}
    </Dialog>
  );
});

export default DialogStyled;
