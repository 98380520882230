import qs from 'qs';
import {MARKETING_TABS} from './enums';

export const getInitialTab = (search: string): MARKETING_TABS => {
  const initialTab = getCurrentTabFromQuery(search);

  return !!initialTab ? initialTab : MARKETING_TABS.Status;

  function getCurrentTabFromQuery(query: string): MARKETING_TABS | null {
    const parsedQuery = qs.parse(query, {ignoreQueryPrefix: true});

    return !!parsedQuery.tab ? (parsedQuery.tab as MARKETING_TABS) : null;
  }
};
