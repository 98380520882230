import {createContext, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import qs from 'qs';
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {DragDropContext, Droppable, Draggable, DropResult, ResponderProvided} from 'react-beautiful-dnd';
//translation
import {useTranslation} from 'react-i18next';
//redux
import {Store} from 'redux/root';
import {getOrgInfo} from 'redux/organization-service/actions';
import {chatClient, resetChatCounter} from 'redux/chat-service/actions';
import {selectorGetSmartGroupsAll} from 'redux/smartGroups-service/selector';
import {selectorGetOrgActiveStatus, selectorGetOrgId, selectorGetSchoolId} from 'redux/organization-service/selector';
import {changeSmartGroupOrder, getSmartGroupsAll} from 'redux/smartGroups-service/actions';
import {getVolunteers, getVolunteersByGroup, resetVolunteers} from 'redux/volunteer-service/actions';
import {resetError, setError} from 'redux/error-service/action';
import {selectorGetDashboardVolunteers} from 'redux/dashboard-service/selector';
import {selectorGetUserRoles, selectorGetUserAddress, selectorGetUserData} from 'redux/user-service/selector';
import {getDashboardInfo} from 'redux/dashboard-service/action';
import {getSignupRequestsTotal, getSignupRequestsAll, resetSignupRequests} from 'redux/signupRequests-service/actions';
import {selectorTotalSignupRequests} from 'redux/signupRequests-service/selector';
//functions
import {parseQueryStringVolunteersSearch} from 'core/functions';
//types
import {
  IGroupsResponse,
  ISearchUserClaimsRequest,
  ISearchVolunteersByGroupRequest,
  ISearchVolunteersRequest,
  ISearchVolunteersSort,
  ORGANISATION_ACTIVE_STATUS,
  PaginationRequest,
  VOLUNTEER,
} from '@joc/api-gateway';
//constants
import {INITIAL_PAGINATION, ORGANISATION_VOLUNTEER_TABS} from 'core/constants';
import {urls} from 'core/appUrls';
import {DEFAULT_SCROLLABLE_CONTAINER} from 'shared/components/Buttons/ButtonUpToTop/constants';
//components
import {VolunteerSidebar} from './VolunteerSidebar';
import VolunteersTable from 'components/Organization/Volunteers/Table';
import TabNav from 'shared/components/TabNav';
import TabNavItem from 'shared/components/TabNav/TabNavItem';
import SignupRequestsTable from 'components/Organization/Volunteers/SignupRequestsTable';
import SmartGroupCreate from 'components/Organization/Volunteers/SmartGroup/SmartGroupCreate';
import SmartGroupEdit from 'components/Organization/Volunteers/SmartGroup/SmartGroupEdit';
//styles
import styles from './Volunteers.module.scss';

export const VolunteersPageContext = createContext({} as VolunteersPageContextType);

export type VolunteersPageContextType = {
  isLoading: boolean;
  sort: ISearchVolunteersSort | undefined;
  isClearSearch: boolean;
  setPagination: Dispatch<
    SetStateAction<{
      skip: number;
      take: number;
    }>
  >;
  changeVisibilityFilters: () => void;
  setRequestBody: Dispatch<SetStateAction<ISearchVolunteersRequest | ISearchVolunteersByGroupRequest | undefined>>;
  setIsClearSearch: Dispatch<SetStateAction<boolean>>;
  refreshVolunteers: () => void;
  setIsEditingSmartGroup: (isEdit: boolean) => void;
  isEditingSmartGroup: boolean;
};

const VolunteersPage: FC<VolunteersPageProps> = ({
  getSmartGroupsAll,
  smartGroups,
  orgId,
  userData,
  schoolId,
  orgStatus,
  getVolunteers,
  getVolunteersByGroup,
  resetError,
  setError,
  resetVolunteers,
  volunteersTotal,
  userRoles,
  userLocation,
  getDashboardInfo,
  getSignupRequestsAll,
  getSignupRequestsTotal,
  signupRequestsTotal,
  resetSignupRequests,
}: VolunteersPageProps) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [processSmartGroups, setProcessSmartGroups] = useState<Array<IGroupsResponse>>([]);

  const [pagination, setPagination] = useState(INITIAL_PAGINATION);
  const [requestBody, setRequestBody] = useState<ISearchVolunteersRequest | ISearchVolunteersByGroupRequest>();
  const [visibilityFilters, setVisibilityFilters] = useState(false);
  const [isClearSearch, setIsClearSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreatingSmartGroup, setIsCreatingSmartGroup] = useState<boolean>(false);
  const [isEditingSmartGroup, setIsEditingSmartGroup] = useState<boolean>(false);
  const [isShowSignupRequests, setIsShowSignupRequests] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ORGANISATION_VOLUNTEER_TABS>(ORGANISATION_VOLUNTEER_TABS.ALL);

  const {t} = useTranslation(['errors', 'students', 'volunteers', 'buttons', 'common']);

  const changeVisibilityFilters = useCallback(() => {
    setVisibilityFilters(!visibilityFilters);
  }, [visibilityFilters]);

  const getVolunteersRequest = useCallback(
    async (checkedPagination: PaginationRequest) => {
      const groupId = (requestBody as ISearchVolunteersByGroupRequest)?.groupId;
      setIsLoading(true);
      try {
        if (groupId && !isEditingSmartGroup) {
          // @ts-ignore
          if (groupId === +qs.parse(location.search, {ignoreQueryPrefix: true})?.groupId) {
            await getVolunteersByGroup({
              ...requestBody,
              pagination: checkedPagination,
              organizationId: +orgId,
            });
          }
        } else if (isShowSignupRequests) {
          await getSignupRequestsAll(orgId, {
            ...(requestBody as ISearchUserClaimsRequest),
            pagination: checkedPagination,
          });
        } else {
          await getVolunteers(orgId, {...requestBody, pagination: checkedPagination});
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setError(error?.response?.message || error.message, error.code);
      }
    },
    [pagination, orgId, requestBody, isShowSignupRequests]
  );

  const refreshVolunteers = useCallback(() => {
    resetVolunteers();
    resetError();
    pagination.skip === INITIAL_PAGINATION.skip
      ? getVolunteersRequest(PaginationRequest.fromJS(INITIAL_PAGINATION))
      : setPagination(INITIAL_PAGINATION);
  }, [pagination, getVolunteersRequest]);

  const volunteersPageContextValue: VolunteersPageContextType = useMemo(() => {
    return {
      isLoading,
      isEditingSmartGroup,
      isClearSearch,
      sort: requestBody?.sort,
      setPagination,
      changeVisibilityFilters,
      setRequestBody,
      setIsClearSearch,
      refreshVolunteers,
      setIsEditingSmartGroup,
    };
  }, [isLoading, isClearSearch, requestBody, changeVisibilityFilters, refreshVolunteers, isEditingSmartGroup]);

  // Tab handlers
  const isTabActive = (tabType: ORGANISATION_VOLUNTEER_TABS | null) => tabType === activeTab;

  const generalChangeTabHandler = (query?: string) => {
    setIsClearSearch(true);
    history.push({search: query || ''});
  };

  const allVolunteersTabClickHandler = () => {
    generalChangeTabHandler();
    setActiveTab(ORGANISATION_VOLUNTEER_TABS.ALL);
  };

  const volunteersSignupRequestsTabClickHandler = () => {
    generalChangeTabHandler('showSignupRequests=true');
    setActiveTab(ORGANISATION_VOLUNTEER_TABS.SIGNUP_REQUESTS);
  };

  const smartGroupTabClickHandler = (id: number) => {
    const groupId = (requestBody as ISearchVolunteersByGroupRequest)?.groupId;
    if (groupId && +id === +groupId) return;
    generalChangeTabHandler(qs.stringify({groupId: id}));
    setActiveTab(ORGANISATION_VOLUNTEER_TABS.SMART_GROUP);
    setIsEditingSmartGroup(false);
  };

  const createSmartGroupClickHandler = () => {
    resetVolunteers();
    generalChangeTabHandler('createSmartGroup=true');
    setActiveTab(ORGANISATION_VOLUNTEER_TABS.CREATE_SMART_GROUP);
  };

  const handleOrganizationId = async (id: string) => {
    const orgId = localStorage.getItem('organisationId');
    const isFindOrg = userData?.organizations?.find((org) => org.id === id);

    if (orgId && id !== orgId.toString() && isFindOrg) {
      dispatch(getOrgInfo(id));
      dispatch(resetChatCounter());
      localStorage.setItem('organisationId', id);
      history.push({
        pathname: urls.orgVolunteers,
        search: location.search,
      });
      chatClient.disconnectUser();
    }

    if (!isFindOrg) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    setIsCreatingSmartGroup(activeTab === ORGANISATION_VOLUNTEER_TABS.CREATE_SMART_GROUP);
    setIsEditingSmartGroup(false);
    setIsShowSignupRequests(activeTab === ORGANISATION_VOLUNTEER_TABS.SIGNUP_REQUESTS);
    setRequestBody({});
  }, [activeTab]);

  useEffect(() => {
    if (!!orgId && requestBody !== undefined) {
      const checkedPagination =
        pagination.take > 0
          ? PaginationRequest.fromJS(pagination)
          : PaginationRequest.fromJS({...pagination, take: 10});
      getVolunteersRequest(checkedPagination);
    }
  }, [getVolunteersRequest]);

  useEffect(() => {
    const isCreatingSmartGroup = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })?.createSmartGroup;
    if (isCreatingSmartGroup && requestBody === undefined) return;

    resetVolunteers();
    resetSignupRequests();
    resetError();
    const data = parseQueryStringVolunteersSearch(location.search, userLocation);
    if (data.organizationId) {
      handleOrganizationId(data.organizationId.toString());
      delete data.organizationId;
    }
    setPagination(INITIAL_PAGINATION);
    setRequestBody(data);
  }, [location.search, isEditingSmartGroup]);

  useEffect(() => {
    if (orgId) {
      getSmartGroupsAll(orgId);
      getSignupRequestsTotal(orgId);
      getDashboardInfo(orgId);
    }
  }, [orgId]);

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    const isCreatingSmartGroup = query?.createSmartGroup;
    const isShowSignupRequests = query?.showSignupRequests;

    if (isCreatingSmartGroup) setActiveTab(ORGANISATION_VOLUNTEER_TABS.CREATE_SMART_GROUP);
    if (isShowSignupRequests) setActiveTab(ORGANISATION_VOLUNTEER_TABS.SIGNUP_REQUESTS);

    return () => {
      resetError();
      resetVolunteers();
      resetSignupRequests();
    };
  }, []);

  useEffect(() => {
    setProcessSmartGroups(smartGroups.sort((a, b) => a.sequenceNum - b.sequenceNum));
  }, [smartGroups]);

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    // eslint-disable-next-line no-useless-return
    if (!result?.destination) return;

    const reorderedItems = Array.from(processSmartGroups);
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    dispatch(changeSmartGroupOrder(removed.id, result.destination.index + 1, orgId));
    //
    setProcessSmartGroups(reorderedItems);
  };

  return (
    <>
      <div className={cx(styles.wrapper, DEFAULT_SCROLLABLE_CONTAINER)}>
        <div className={styles.container}>
          <h1 className="title">{t(`${schoolId ? 'students' : 'volunteers'}:title`)}</h1>
          <TabNav>
            <TabNavItem
              parentClassName={styles.tabNavItem}
              counter={volunteersTotal}
              title={t('common:titles.all')}
              activeTab={isTabActive(ORGANISATION_VOLUNTEER_TABS.ALL)}
              clickHandler={allVolunteersTabClickHandler}
            />
            {userRoles?.find((i) => i.roleName === VOLUNTEER.ADMIN) && !!signupRequestsTotal && (
              <TabNavItem
                parentClassName={cx(styles.signupRequests, {
                  [styles.disabled]: orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED,
                })}
                counter={signupRequestsTotal}
                title={t('volunteers:signupRequests')}
                activeTab={isTabActive(ORGANISATION_VOLUNTEER_TABS.SIGNUP_REQUESTS)}
                clickHandler={volunteersSignupRequestsTabClickHandler}
                isSignupRequests
              />
            )}
            {userRoles?.find((i) => i.roleName === VOLUNTEER.ADMIN || i.roleName === VOLUNTEER.MARKETER) && (
              <TabNavItem
                parentClassName={cx(styles.generateGroup, {
                  [styles.disabled]: orgStatus?.status === ORGANISATION_ACTIVE_STATUS.SUSPENDED,
                })}
                title={t('buttons:button.createSmartGroup')}
                activeTab={isTabActive(ORGANISATION_VOLUNTEER_TABS.CREATE_SMART_GROUP)}
                clickHandler={createSmartGroupClickHandler}
              />
            )}
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="list" direction="horizontal">
                {(provided) => (
                  <div
                    className="horizontal-list"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      display: 'flex',
                      gap: 4,
                    }}
                  >
                    {processSmartGroups.map((group, index) => (
                      <Draggable key={String(group.id)} draggableId={String(group.id)} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <TabNavItem
                              parentClassName={styles.tabNavItem}
                              key={group.id}
                              title={group.groupName}
                              counter={group.total}
                              activeTab={
                                (requestBody as ISearchVolunteersByGroupRequest)?.groupId?.toString() ===
                                  group.id.toString() && isTabActive(ORGANISATION_VOLUNTEER_TABS.SMART_GROUP)
                              }
                              isSmartGroup
                              groupId={group.id}
                              clickHandler={() => smartGroupTabClickHandler(group.id)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </TabNav>
          <VolunteersPageContext.Provider value={volunteersPageContextValue}>
            {isCreatingSmartGroup ? (
              <SmartGroupCreate />
            ) : isEditingSmartGroup ? (
              <SmartGroupEdit />
            ) : isShowSignupRequests ? (
              <SignupRequestsTable />
            ) : (
              <VolunteersTable />
            )}
          </VolunteersPageContext.Provider>
        </div>
        <VolunteerSidebar
          isOpen={visibilityFilters}
          isCloseFilters={!visibilityFilters}
          setIsClearSearch={setIsClearSearch}
          handleClose={changeVisibilityFilters}
          changeVisibilityFilters={changeVisibilityFilters}
        />
      </div>
      <Snackbar open={isOpen} autoHideDuration={10000} onClose={() => setIsOpen(false)}>
        <Alert onClose={() => setIsOpen(false)} severity="error">
          <p className={styles.alert}>You are currently logged in as an Admin in another organization.</p>
          <p className={styles.alert}>
            Please log out and log in again to access the organization you are trying to reach.
          </p>
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (store: Store) => ({
  smartGroups: selectorGetSmartGroupsAll(store),
  orgId: selectorGetOrgId(store),
  orgStatus: selectorGetOrgActiveStatus(store),
  volunteersTotal: selectorGetDashboardVolunteers(store),
  userRoles: selectorGetUserRoles(store),
  userLocation: selectorGetUserAddress(store),
  schoolId: selectorGetSchoolId(store),
  signupRequestsTotal: selectorTotalSignupRequests(store),
  userData: selectorGetUserData(store),
});
const mapDispatchToProps = {
  getSmartGroupsAll,
  getVolunteers,
  getOrgInfo,
  getVolunteersByGroup,
  resetError,
  setError,
  resetVolunteers,
  getDashboardInfo,
  getSignupRequestsAll,
  getSignupRequestsTotal,
  resetSignupRequests,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type VolunteersPageProps = ConnectedProps<typeof connector>;

export default connector(VolunteersPage);
