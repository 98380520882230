import {FC, useEffect, useMemo} from 'react';
import {Field, Form, useFormikContext} from 'formik';
import moment from 'moment/moment';
import cx from 'classnames';
// components
import Input from '../../../../../../shared/inputs/Input';
import {DatePicker} from '../../../../../../shared/components/Pickers/NewUI/DatePicker';
import MediaInput from '../../../../../../shared/components/MediaInput';
import {StatusTimePicker} from './StatusTimePicker';
// api
import {IStatusResponse} from '@joc/api-gateway';
// constants
import {MAX_CAPTION_LENGTH} from '../validation';
// types
import {FieldBaseProps} from '../../../../../../core/types';
// styles
import styles from './StatusForm.module.scss';

type StatusFormProps = {
  currentValues: (values: Partial<IStatusResponse>) => void;
  formState: (state: {isValid: boolean; dirty: boolean}) => void;
  isEditMode: boolean;
};

const StatusForm: FC<StatusFormProps> = ({currentValues, formState, isEditMode}) => {
  const {values, isValid, initialValues, dirty, setFieldTouched} = useFormikContext<
    Partial<IStatusResponse> & {startTime: string}
  >();

  useEffect(() => {
    currentValues(values as Partial<IStatusResponse>);
  }, [values]);

  useEffect(() => {
    setFieldTouched('caption', true).finally();
    setFieldTouched('link', true).finally();
    setFieldTouched('linkTitle', true).finally();

    formState({isValid, dirty});
  }, [isValid, dirty]);

  const disableDateTime = useMemo(
    () =>
      moment().isSameOrAfter(initialValues.startDate) &&
      moment(initialValues.startDate).add(1, 'day').isBefore(moment().add(1, 'day')),
    [initialValues]
  );

  return (
    <Form className={styles.statusForm}>
      <Field
        name="mediaPath"
        label="Photo or Video"
        maxMegabyteSize={100}
        toS3bucket
        isRequired
        placeholder="Photos are displayed for 60 seconds, Videos - 1 minute and 30 seconds"
        parentClassName={styles.statusForm__feild}
        component={MediaInput}
      />

      <Field name="caption">
        {(props: FieldBaseProps) => (
          <div className={styles.statusForm__caption}>
            <Input
              label="Status caption"
              placeholder="Status caption"
              parentClassName={styles.statusForm__feild}
              {...props}
            />
            {!props.meta.error || !props.meta.touched ? (
              <span
                className={cx(styles.statusForm__caption_limit, {
                  [styles.statusForm__caption_limit_error]: props.field.value.trim().length > MAX_CAPTION_LENGTH,
                })}
              >
                Maximum characters: {props.field.value.trim().length}/{MAX_CAPTION_LENGTH}
              </span>
            ) : (
              <></>
            )}
          </div>
        )}
      </Field>
      <Field
        name="linkTitle"
        label="Link title"
        placeholder="Link title"
        parentClassName={styles.statusForm__feild}
        component={Input}
      />
      <Field
        name="link"
        label="URL-Link"
        placeholder="https://"
        parentClassName={styles.statusForm__feild}
        component={Input}
      />

      <div className={styles.statusForm__pickers}>
        <Field
          name="startDate"
          label="Publication Date"
          availableFuture
          disabled={isEditMode && disableDateTime}
          disablePast
          parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
          component={DatePicker}
        />
        <Field
          name="startDate"
          label="Publication time"
          parentClassName={cx(styles.statusForm__feild, styles.statusForm__pickers__field)}
          disablePast
          disabled={isEditMode && disableDateTime}
          component={StatusTimePicker}
        />
      </div>
    </Form>
  );
};

export default StatusForm;
