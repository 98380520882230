import {useMutation, useQueryClient} from '@tanstack/react-query';
import {API} from '../../../core/API';
import {STATUSES_QUERY_KEY} from '../../../constants';

interface IUseEditStatus {
  deleteStatus: ({statusId, organizationId}: {statusId: number; organizationId: string}) => void;
}

const deleteStatusApi = async (statusId: number, organizationId: string) => {
  return await API.deleteStatus(statusId, organizationId);
};

export const useDeleteStatus = (): IUseEditStatus => {
  const queryClient = useQueryClient();
  const {mutate: deleteStatus} = useMutation<void, Error, {statusId: number; organizationId: string}>({
    mutationFn: ({statusId, organizationId}) => deleteStatusApi(statusId, organizationId),
    onSettled: () => queryClient.invalidateQueries({queryKey: [STATUSES_QUERY_KEY]}),
  });

  return {deleteStatus};
};
