import {FC, useEffect, useRef, useState} from 'react';
import moment, {Moment} from 'moment';

// new material-ui
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';

// components
import FieldWrapper from 'shared/inputs/FieldWrapper';

// hooks
import {useClickOutside} from 'hooks/useClickOutside';

// types
import {AppDateTimePickerProps} from '../types';

// styled
import {DatePickerStyled} from './styles';

export const DatePicker: FC<AppDateTimePickerProps> = (fieldBaseProps) => {
  const [value, setValue] = useState<Moment | null>(null);
  const {field, form, disabled} = fieldBaseProps;
  const {setFieldValue, setFieldTouched} = form;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const handleClosePicker = () => setIsPickerOpen(false);
  const popperRef = useClickOutside<HTMLDivElement>(handleClosePicker);
  const dialogRef = useClickOutside<HTMLDivElement>(handleClosePicker);

  const inputRef = useRef<HTMLImageElement>();

  const onChange = (date: Moment | null) => {
    if (fieldBaseProps.disablePast) {
      setFieldValue(field.name, date?.isBefore(moment()) ? moment().toDate() : date?.toDate());
    } else {
      setFieldValue(field.name, date);
    }
    setIsHighlight(true);
  };

  const onTouchHandler = () => {
    setFieldTouched(field.name, true);
  };

  useEffect(() => {
    setIsHighlight(!!field?.value);
    setValue(moment(field.value));
  }, [field.value]);

  return (
    <FieldWrapper {...fieldBaseProps}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePickerStyled
          disabled={disabled}
          value={value}
          onChange={onChange}
          onSelectedSectionsChange={onTouchHandler}
          selectedSections={null}
          open={isPickerOpen}
          disablePast={fieldBaseProps?.disablePast}
          disableFuture={fieldBaseProps?.disableFuture}
          onAccept={handleClosePicker}
          onClose={() => handleClosePicker()}
          views={['month', 'year', 'day']}
          slotProps={{
            textField: {
              inputRef,
              onClick: () => {
                inputRef.current?.blur();
                setIsPickerOpen(true);
              },
              sx: {
                '.MuiOutlinedInput-input': {
                  color: isHighlight ? '#1C2F4C !important' : '#5B768B !important',
                },
              },
            },
            day: {
              sx: {
                '&.Mui-selected': {
                  backgroundColor: '#4B5FF6 !important',

                  '&:hover': {
                    backgroundColor: '#4B5FF6',
                  },
                },
              },
            },
            actionBar: {
              sx: {
                '& .MuiButtonBase-root': {
                  color: '#4B5FF6',
                },
              },
            },
            dialog: {
              ref: dialogRef,
              sx: {
                '.MuiPaper-root': {
                  borderRadius: '10px',
                },
                'button, li': {
                  borderRadius: '50%',

                  '&:focus': {
                    backgroundColor: 'unset',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#4B5FF6 !important',

                    '&:hover': {
                      backgroundColor: '#4B5FF6',
                    },
                  },
                },
              },
            },
            popper: {
              ref: popperRef,
              sx: {
                '.MuiPaper-root': {
                  borderRadius: '10px',
                },
                'button, li': {
                  borderRadius: '50%',

                  '&:focus': {
                    backgroundColor: 'unset',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#4B5FF6 !important',

                    '&:hover': {
                      backgroundColor: '#4B5FF6',
                    },
                  },
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </FieldWrapper>
  );
};
