import {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
//redux
import {useSelector} from 'react-redux';
import {selectorGetOrgInfo} from '../../../../redux/organization-service/selector';
// api
import {IDateRange} from '@joc/api-gateway';
// context
import {StatusesContextProvider} from './Statuses.context';
// constants
import {BUTTON_ICONS} from '../../../../core/constants';
//components
import InputSearch from '../../../../shared/components/Inputs/InputSearch';
import ButtonDefault from '../../../../shared/components/Buttons/ButtonsDefault';
import StatusesTable from './StatusesTable';
import StatusesPopup from './StatusesPopup';
import {StatusesSidebar} from './StatusesSidebar';
import ButtonImage from '../../../../shared/components/Buttons/ButtonImage';
//styles
import styles from './Statuses.module.scss';

const Statuses: FC = () => {
  const {t} = useTranslation(['inputs', 'buttons', 'tooltips']);
  const [isClearSearch, setIsClearSearch] = useState(true);

  const [isShowStatusPopup, setIsShowStatusPopup] = useState(false);
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<IDateRange>({fromDate: undefined, toDate: undefined});
  const [sidebarVisibility, setSidebarVisibility] = useState(false);
  const organizationInfo = useSelector(selectorGetOrgInfo);

  const closePopup = useCallback(() => {
    setIsShowStatusPopup(false);
  }, []);

  const filterClickHandler = () => setSidebarVisibility(!sidebarVisibility);

  return (
    <StatusesContextProvider value={{search, setSearch, filters, setFilters, sidebarVisibility, setSidebarVisibility}}>
      <section className={styles.statuses}>
        <div className={styles.statuses__header}>
          <div className={styles.statuses__header__filters}>
            <InputSearch
              placeholder={t('inputs:placeholders.searchWithDots')}
              classList={['big']}
              parentClass={styles.statuses__search}
              changeHandler={(value) => setSearch(value)}
              isClearSearch={isClearSearch}
              setIsClearSearch={setIsClearSearch}
            />
            <ButtonImage
              viewStyle={BUTTON_ICONS.filter}
              clickHandler={filterClickHandler}
              title={t('tooltips:chatFilter')}
            />
          </div>
          <ButtonDefault
            title={t('buttons:button.create')}
            classList={['primary', 'sm']}
            parentClassName={styles.statuses__create}
            clickHandler={() => setIsShowStatusPopup(true)}
          />
        </div>
        {organizationInfo?.id ? <StatusesTable orgId={organizationInfo.id} /> : null}

        {isShowStatusPopup && <StatusesPopup isModalOpen={isShowStatusPopup} onClose={closePopup} />}
        <StatusesSidebar />
      </section>
    </StatusesContextProvider>
  );
};

export default Statuses;
