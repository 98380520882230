import {FC, useMemo, useRef} from 'react';
import {useStatusesContext} from '../../Statuses.context';

import styles from './StatusesFilters.module.scss';
import FiltersHeader from '../../../../../../components/Organization/Filters/FiltersHeader';
import {IDateRange} from '@joc/api-gateway';
import {Form, Formik, FormikProps} from 'formik';
import {DateTimeFields} from '../../../../../../components/Organization/Opportunities/OpportunitiesFilters/OpportunitiesFiltersForm/DateTimeFields';
import ButtonDefault from '../../../../../../shared/components/Buttons/ButtonsDefault';
import {useTranslation} from 'react-i18next';
import {DateRangeFiltersValidationSchema} from '../../../../../../core/validation';

type FormFilters = {[Key in keyof IDateRange]: Date | null};

const StatusesFilters: FC = () => {
  const {t} = useTranslation('form');
  const formikRef = useRef<FormikProps<FormFilters>>(null);

  const {filters, setFilters, sidebarVisibility, setSidebarVisibility} = useStatusesContext();

  const changeVisibilityHandler = () => setSidebarVisibility(!sidebarVisibility);

  const resetFilters = () => {
    setFilters((prevState) => ({...prevState, fromDate: undefined, toDate: undefined}));
    formikRef.current?.resetForm();
  };

  const initialValues = useMemo<FormFilters>(
    () => ({
      fromDate: filters?.fromDate ? filters.fromDate : null,
      toDate: filters?.toDate ? filters.toDate : null,
    }),
    [filters]
  );

  const handleSubmit = (values: FormFilters) => setFilters(values as IDateRange);

  return (
    <div className={styles.statusesFilters}>
      <FiltersHeader changeVisibilityFilters={changeVisibilityHandler} resetForm={resetFilters} />
      <hr className={styles.separator} />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={DateRangeFiltersValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({isValid}) => (
          <Form className={styles.statusesFilters__form}>
            <DateTimeFields showDateInThePast showDateInTheFuture />

            <div className={styles.statusesFilters__form_actions}>
              <ButtonDefault disabled={!isValid} title={t('filter.apply')} submitType classList="secondary md" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StatusesFilters;
