import {useMutation, useQueryClient} from '@tanstack/react-query';
import {IStatusRequest, UpdateStatusRequest} from '@joc/api-gateway';
import {API} from '../../../core/API';
import {StatusResponse} from '@joc/api-gateway/lib/api-client';
import {STATUSES_QUERY_KEY} from '../../../constants';

interface IUseEditStatus {
  editStatus: ({statusId, data}: {statusId: number; data: IStatusRequest}) => void;
}

const editStatusApi = async (statusId: number, data: IStatusRequest) => {
  return await API.updateStatus(statusId, undefined, UpdateStatusRequest.fromJS(data));
};

export const useEditStatus = (): IUseEditStatus => {
  const queryClient = useQueryClient();
  const {mutate: editStatus} = useMutation<StatusResponse, Error, {statusId: number; data: IStatusRequest}>({
    mutationFn: ({statusId, data}) => editStatusApi(statusId, data),
    onSettled: () => queryClient.invalidateQueries({queryKey: [STATUSES_QUERY_KEY]}),
  });

  return {editStatus};
};
