import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {MARKETING_TABS} from './enums';

type MarketingContextValues = {
  activeTab: MARKETING_TABS;
  isUpdateSuccess: boolean;
  setIsUpdateSuccess: Dispatch<SetStateAction<boolean>>;
  setStatusTotal: Dispatch<SetStateAction<number>>;
};

const MarketingContext = createContext<MarketingContextValues>({} as MarketingContextValues);

export const MarketingContextProvider = MarketingContext.Provider;
export const useMarketingContext = () => useContext(MarketingContext);
