import {useMutation, useQueryClient} from '@tanstack/react-query';
import {IStatusRequest, StatusRequest} from '@joc/api-gateway';
import {API} from '../../../core/API';
import {StatusResponse} from '@joc/api-gateway/lib/api-client';
import {STATUSES_QUERY_KEY} from '../../../constants';

interface IUseCreateStatus {
  createStatus: ({organizationId, status}: {organizationId: string; status: IStatusRequest}) => void;
}

const createNewStatus = async (organizationId: string, status: IStatusRequest) => {
  return API.createStatus(organizationId, StatusRequest.fromJS(status));
};

export const useCreateStatus = (): IUseCreateStatus => {
  const queryClient = useQueryClient();
  const {mutate: createStatus} = useMutation<StatusResponse, Error, {organizationId: string; status: IStatusRequest}>({
    mutationFn: ({organizationId, status}) => createNewStatus(organizationId, status),
    onSettled: () => queryClient.invalidateQueries({queryKey: [STATUSES_QUERY_KEY]}),
  });

  return {createStatus};
};
