// react
import {FC} from 'react';
// mui
import Drawer from '@material-ui/core/Drawer';
import {makeStyles} from '@material-ui/core/styles';
// data
import {useStatusesContext} from '../Statuses.context';
import StatusesFilters from './StatusesFilters';

const useStyles = makeStyles({
  paper: {
    zIndex: 99,
  },
});

export const StatusesSidebar: FC = () => {
  const classes = useStyles();

  const {sidebarVisibility, setSidebarVisibility} = useStatusesContext();

  const handleClose = () => {
    setSidebarVisibility(false);
  };

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      classes={{
        paper: classes.paper,
      }}
      open={sidebarVisibility}
      onClose={handleClose}
    >
      <StatusesFilters />
    </Drawer>
  );
};
